<template>
    <div class="row">
        <div class="col-12" >
            <g-loading v-if="firstLoading" />
            <g-table v-else :filter="filterFormModel" :itemCount="itemCount" :items="items" :fields="fields" @load="load" @reset="reset"
                     title="Lịch sử email" titleIcon="fa fa-envelope">
                <template slot="filter">
                    <time-range-picker class="mr-1" :model="filterFormModel" label="Thời gian gửi" fromAttribute="from_time" toAttribute="to_time" :defaultShortcut="10" @change="search" />
                    <dropdown type="bordered" class="mr-2" icon="fas fa-globe-europe"  :model="filterFormModel" attribute="status" :options="status" :isFilter="true" @change="search" />
                </template>
                <template #cell(customeTime)="{item}">
                    <div><span class="small text-gray-999">Ngày tạo:</span> {{item.create_time * 1000 | datetimeFormat}}</div>
                    <div v-if="item.send_time != 0"><span class="small text-gray-999">Ngày gửi:</span> {{item.send_time * 1000 | datetimeFormat}}</div>  
                </template>
                <template #cell(status)="{item}">
                    <span v-if="item.is_send == 1 && item.is_run == 1" class="badge bg-success ml-1"> Đã gửi</span>
                    <span v-if="item.is_send != 1 && item.is_run == 1" class="badge bg-danger ml-1"> Gửi lỗi {{ item.message}}</span>
                    <span v-if="item.is_run != 1 && item.is_send !=1" class="badge bg-secondary ml-1"> Chờ gửi </span>
                </template>
                <template #cell(action)="{item}">
                    <div class="btn-action-3" >
                        <g-button v-if="permissions('mailbox_send') && ((item.is_send != 1 && item.is_run != 1) || (item.is_send != 1 && item.is_run == 1))" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-sync-alt" @click="sendAgain(item.id)" content="Gửi lại" />
                        <g-button v-if="permissions('mailbox_update')" :loading="updateLoading" size="sm" variant="outline-gray" class="ml-1 mb-1" icon="fa fa-eye" @click="update(item.id)" />
                    </div>
                </template>
            </g-table>
            <b-modal  v-model="showForm" size="lg" :title="formModel.subject" @ok="save" ok-title="Lưu lại" cancel-title="Huỷ" no-close-on-backdrop no-enforce-focus>
                <div class="row">
                    <div class="col-md">
                        <label class="font-weight-normal"> Gửi đến:  </label>
                        <span attribute="to"> {{formModel.to}} </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md">
                        <label class="font-weight-normal"> Nội dung: </label>
                        <form-textarea label="Nội dung" :required='false' :model="formModel" :errors="formErrors" v-html="formModel.body" attribute="body" desc="" />
                    </div>
                </div>  
                <template v-slot:modal-footer="{ ok, cancel }">
                    <b-button variant="secondary" class="float-right" @click="cancel">Đóng</b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import list from "@/mixins/List";
    import crud from '@/mixins/CRUD';
    import role from '@/mixins/Role';
    import Dropdown from '@/components/Dropdown';
    export default {
        components: {Dropdown},
        mixins: [list, crud,role],
        data: function () {
            return {
                service: '/mailbox',
                fields: [
                    {key: 'subject', label: "Tiêu đề"},
                    {key: 'to', label: "Người nhận"},
                    // {key: 'from', label: "Người gửi"},
                    {key: 'customeTime', label: "Thời gian"},
                    {key: 'status', label: "Trạng thái"},
                    {key: 'action', label: "Hành động", class: 'text-right'},
                ],
                defaultFilterFormModel: {
                    keyword: '',
                    status: null,
                },
                defaultFormModel: {
                },
                status: [
                    {value: null, text: "Trạng thái: Tất cả"},
                    {value: 0, text: "Chờ gửi"},
                    {value: 1, text: "Đã gửi"},
                    {value: 2, text: "Gửi lỗi"},
                ],
            };
        },
        methods: {
            changeType(){
                this.formModel.target_id = null;
            },
            sendAgain(id){
                this.$swal({
                    title: "Bạn chắc chắn muốn gửi lại email này chứ ?",
                    // text: "Bạn có muốn gửi lại Email này ??",
                    icon: "warning",
                    showCancelButton: true
                }).then(async (result) => {
                    if (result.value) {
                        this.$service({url: `${this.service}/sendagain?id=${id}`, method: 'put'}).then(response => {
                            if (response.data) {
                                this.$swal({
                                    text: 'Gửi lại mail thành công',
                                    icon: 'success',
                                    toast: true,
                                    position: 'bottom-end',
                                    timer: 5000
                                });
                            } else {
                                this.$swal({
                                    title: "Có lỗi",
                                    text: 'Gửi lại mail không thành công',
                                    icon: 'error',
                                    toast: true,
                                    position: 'bottom-end',
                                    timer: 5000
                                });
                            }
                            this.load();
                        })
                    }
                });
            }
        }
    }
</script>